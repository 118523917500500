@import "../../assets/scss/variables-global";

.srs-select {
  min-width: 300px;
}
.srs-select-sm {
  min-width: 110px;
}

.srs-select-adapter__control {
  border-radius: 0 !important;
  border-width: 0 !important;
  box-shadow: none !important;
  border-bottom: $input-border-width solid $input-border-color !important;

  &:hover {
    border-color: $primary !important;
  }
}

.srs-select-adapter__value-container {
  min-height: 49px;
}
