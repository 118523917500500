@import "../../assets/scss/variables-global";

.srs-tree {
  color: $primary;

  .border-left-indent {
    border-left: 3px solid;
    padding-left: 15px;
  }

  li {
    min-height: 30px;
  }
}

.srs-node i {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.convoluted ul {
  display: none;
}

.convoluted > li > button > i.fa-chevron-down:before {
  content: "\F054";
}

.drag-divider {
  position: absolute;
  bottom: -5px;
  z-index: 100;
  height: 10px;
  width: 100%;
  border-radius: 5px;
}

.srs-tree-line-container > .srs-tree > ul,
.srs-tree-container > .srs-tree > ul {
  padding-left: 0;
}

.srs-tree-line-container
  > .srs-tree
  > ul
  > .srs-tree
  > ul
  > li
  > .w-100
  > .srs-branch
  > .col-12
  > .col-5
  > .ml-5,
.srs-tree-container
  > .srs-tree
  > ul
  > .srs-tree
  > ul
  > li
  > .w-100
  > .srs-branch
  > .col-12
  > .col-5
  > .ml-5 {
  margin-left: 2rem !important;
}

.srs-tree-line-container
  > .srs-tree
  > ul
  > .srs-tree
  > ul
  > .srs-tree
  > ul
  > li
  > .w-100
  > .srs-branch
  > .col-12
  > .col-5
  > .ml-5,
.srs-tree-container
  > .srs-tree
  > ul
  > .srs-tree
  > ul
  > .srs-tree
  > ul
  > li
  > .w-100
  > .srs-branch
  > .col-12
  > .col-5
  > .ml-5 {
  margin-left: 1rem !important;
}

.srs-tree-line-container {
  .srs-tree li {
    margin-bottom: $gutter;
    padding: $gutter $gutter;
    border: 2px solid $success;
    max-height: 60px;
    background: $white;

    .form-group {
      margin-bottom: 0;
      input {
        padding: 0;
        max-width: 50px;
        text-align: center;
      }
    }
  }
}
