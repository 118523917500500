.srs-input-extra-content {
  position: relative;

  .srs-input-extra-content-body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}
