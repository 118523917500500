@import "../../../../assets/scss/variables-global";

.srs-composition-form > form > div > .srs-tree > ul {
  padding-left: 0;
}

.srs-composition-form .srs-tree {
  color: $primary;

  li {
    padding: 0 $gutter;
  }
}

.srs-composition-form .css-yvszuv-Slider {
  width: 100%;
}

.srs-composition-form-overview {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 270px;
  top: 110px;
  right: 10px;
  z-index: 10;
  font-size: 12px;
}
