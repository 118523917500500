.srs-dashboard-links {
  .card-admin {
    background: linear-gradient(180deg, #ffffff 60.29%, #fff8dc 100%);
    box-shadow: 0px 1px 2px rgba(5, 75, 109, 0.15);
    max-height: 245px !important;
  }
  .card {
    width: 100%;
    max-width: 265px;
    max-height: 220px;
    margin: 10px auto;
    padding-bottom: 25px;
    .clickable-card {
      color: #0073c0;
      cursor: pointer;
    }
    a {
      height: 100%;
    }

    .card-body {
      padding-bottom: 130px;
      h5 {
        font-size: 1.20625rem;
        font-weight: bold;
        margin-top: 16px;
        float: left;
        text-align: left;
        width: 100%;
      }
      h6 {
        color: #ffa800;
        font-size: 1rem;
        font-weight: bold;
        float: left;
        width: 100%;
        text-align: left;
      }
    }
  }
  .icon-area {
    background: #88d210;
    border-radius: 4px;
    float: left;
    text-align: center;
    padding: 22px;
    max-width: 125px;
    max-height: 125px;
    img {
      height: 80px;
      width: 80px;
    }
  }

  .icon-area-gray {
    background: #606060;
    border-radius: 4px;
    float: left;
    text-align: center;
    padding: 22px;
    max-width: 125px;
    max-height: 125px;
    img {
      height: 80px;
      width: 80px;
    }
  }
}
