.srs-dropdown {
  position: relative;
  width: 260px;
  height: 50px;

  &-sm {
    width: 180px;
  }

  .srs-dropdown-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;

    &-open {
      box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    }
  }

  .srs-dropdown-list {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    border-top: 1px solid #f0f7fb;
  }

  .srs-dropdown-icon {
    float: right;
    font-size: 12px;
    margin-top: 7px;
  }

  .srs-dropdown-btn {
    width: 100%;
    min-height: 50px;
    white-space: normal;
    text-align: left;
    background-color: #f7fbfd;
    color: #0073c0;
    transition: background-color 0.3s ease-out;
    overflow-x: hidden;

    &-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-inner {
      background-color: #fff;
      height: auto;
      border-top: 1px solid #f0f7fb;
      border-bottom: 1px solid #f0f7fb;
      font-weight: 400;

      &-selected {
        font-weight: 600;
        background-color: #edf5fb;
      }

      &-title {
        font-weight: 600;
      }

      &-sub {
        font-size: 12px;
        font-weight: 400;
      }
    }

    &:hover {
      background-color: #edf5fb;
    }
  }
  .manage-account-dropdown {
    min-width: 220px;
  }
  .srs-dropdown-logout {
    background: #ffc107;
    color: #2c3136;
  }

  .manage-dropdown-title {
    float: left;
  }
  .manage-dropdown-icon {
    float: right;
    .filter-blue {
      filter: invert(76%) sepia(6%) saturate(3925%) hue-rotate(176deg)
        brightness(88%) contrast(93%);
      max-width: 25px;
      max-height: 25px;
      padding: 0px;
    }
  }
  .add-three-dots {
    overflow-y: hidden;
    span {
      display: inline-block;
      width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px;
      max-width: 200px;
    }
  }
}
