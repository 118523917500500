.react-datepicker-wrapper {
  width: 100%;
}
// .react-datepicker__input-container .form-control {
//   font-size: 15px;
//   line-height: 0;
// }
.react-datepicker__month-container {
  padding: 0 $gutter;
}

.react-datepicker__header {
  background-color: white !important;
}

.react-datepicker__month-text {
  width: 2rem !important;
}