.srs-modal {
  overflow: auto;
  background-color: rgba(2, 2, 2, 0.5);

  &.srs-modal-lg .modal-dialog {
    max-width: 900px;
  }
  &.srs-modal-xl .modal-dialog {
    max-width: 1250px;
  }
}

.srs-modal-close {
  top: 0;
  right: 10px;
}

.modal-content {
  padding: 50px;
}
