.container {
  max-height: 140px;
  padding: 20px 30px 10px 0;
  .imgPart {
    display: flex;
    justify-content: space-between;
    .logo {
      width: 140px;
      object-fit: contain;
    }
    .line {
      display: flex;
      width: 2px;
      height: 100%;
      background-color: rgba(108, 117, 125, 0.5);
      margin-left: 25px;
    }
  }
  .footerFlex {
    display: flex;
    justify-content: space-between;

    .informPart {
      display: flex;
      .link {
        margin-right: 20px;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s;
      }
      .link:last-child {
        margin-right: 0;
      }
    }
  }
}
