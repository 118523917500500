@import "../../assets/scss/variables-global";

.srs-checkbox {
  min-width: 24px;
  min-height: 24px;
  padding-right: 30px;
  input {
    display: none;
  }

  label {
    position: relative;
    left: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  label:before {
    content: "";
    position: absolute;
    left: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 24px;
    width: 24px;
    background: white;
    border: $input-border-width solid $green;
    transition: $transition-base;
  }

  input:checked + label:before {
    background: $green;
  }

  .text-black {
    color: #212529;
  }
}
