@import "assets/scss/variables-global";

.srs-app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 125px 1fr 125px;
}

.srs-app-header {
  img {
    height: 100px;
    padding: 15px 0;
    &.small {
      height: 70px;
    }
  }
}

.srs-app-main {
  padding: 0;
  background-image: url("./assets/images/bg.svg");
  background-position: 100% 75%;
  background-repeat: no-repeat;
  background-color: #edf5fa;
  background-size: 95%;
  height: 100%;
  @media screen and (min-width: 1800px) {
    background-size: 85%;
  }
}

.srs-logout-btn {
  max-width: 100px;
  max-height: 50px;
  font-size: 14px;
}

.color-blue {
  background-image: linear-gradient(
      90deg,
      rgba(111, 147, 218, 0.8),
      rgba(50, 101, 203, 0.8) 50%,
      rgba(43, 86, 173, 0.8)
    ),
    linear-gradient(0deg, rgba(24, 24, 24, 0.65), rgba(112, 112, 112, 0.65));
}
.color-red {
  background-image: linear-gradient(
      90deg,
      rgba(230, 115, 88, 0.8),
      rgba(220, 56, 17, 0.8) 50%,
      rgba(187, 48, 14, 0.8)
    ),
    linear-gradient(0deg, rgba(24, 24, 24, 0.65), rgba(112, 112, 112, 0.65));
}
.color-orange {
  background-image: linear-gradient(
      90deg,
      rgba(255, 182, 76, 0.8),
      rgba(255, 152, 0, 0.8) 50%,
      rgba(217, 129, 0, 0.8)
    ),
    linear-gradient(0deg, rgba(24, 24, 24, 0.65), rgba(112, 112, 112, 0.65));
}
.color-green {
  background-image: linear-gradient(
      90deg,
      rgba(87, 181, 93, 0.8),
      rgba(16, 150, 24, 0.8) 50%,
      rgba(14, 128, 20, 0.8)
    ),
    linear-gradient(0deg, rgba(24, 24, 24, 0.65), rgba(112, 112, 112, 0.65));
}
.color-violet {
  background-image: linear-gradient(
      90deg,
      rgba(183, 76, 183, 0.8),
      rgba(152, 0, 152, 0.8) 50%,
      rgba(129, 0, 128, 0.8)
    ),
    linear-gradient(0deg, rgba(24, 24, 24, 0.65), rgba(112, 112, 112, 0.65));
}
.color-cyan {
  background-image: linear-gradient(
      90deg,
      rgba(76, 182, 215, 0.8),
      rgba(0, 152, 198, 0.8) 50%,
      rgba(0, 129, 168, 0.8)
    ),
    linear-gradient(0deg, rgba(24, 24, 24, 0.65), rgba(112, 112, 112, 0.65));
}
.color-pink {
  background-image: linear-gradient(
      90deg,
      rgba(232, 62, 140, 0.8),
      rgba(233, 82, 151, 0.8) 50%,
      rgba(232, 49, 133, 0.8)
    ),
    linear-gradient(0deg, rgba(24, 24, 24, 0.65), rgba(112, 112, 112, 0.65));
}

.btn-outline-warning:hover {
  color: #fff;
}

.btn-decline {
  width: 100%;
  color: rgb(240, 144, 28);
  background-color: #fff;
  border: 2px solid rgb(240, 144, 28);

  &:hover {
    background-color: rgb(240, 144, 28);
    color: #fff;
  }
}

.break-all {
  word-break: break-all;
}

.actions-col {
  width: 200px;
}

.btn:not(:disabled):not(.disabled).cursor-default {
  cursor: default;
}

.btn {
  .btn-icon {
    width: 20px;
    text-align: center;
  }

  &.btn-input-icon {
    position: absolute;
    top: 0;
    right: 8px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.row-selected {
  background-color: #edf5fa;
}

.srs-iframe {
  height: calc(100vh - 120px);
  width: 100vw;
  position: relative;
  left: 49.2%;
  right: 49.2%;
  margin-left: -50vw;
  margin-right: -50vw;
  border: none;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  //background-color: #fff;

  &--sway {
    height: 623px;
  }
}

.multi-select-wrapper {
  & > div > div {
    border-radius: 0 !important;
    border-width: 0 !important;
    box-shadow: none !important;
    border-bottom: 2px solid #adb5bd !important;
  }

  &:hover > div > div {
    border-color: #0073c0 !important;
  }

  .dropdown-heading {
    height: 48px !important;
  }
}

.ml-n5 {
  margin-left: -3rem !important;
}