.group {
  padding: 0 !important;

  tr:first-child {
    border: none !important;
  }
  tr {
    padding: 0.75rem;
    display: flex;
    width: 100%;
  }
  .cell {
    border-top: 2px solid #dee2e6;
    height: 51px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.title {
  font-size: 24px;
}

.highlightLeft {
  border-left: 5px solid #dee2e6 !important;
}
