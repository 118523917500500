.registration-finish-choices {
  justify-content: space-around !important;
  &:hover {
    cursor: pointer;
  }
  .card {
    width: 100%;
    max-width: 265px;
    max-height: 265px;
    margin: 10px auto;
    padding-bottom: 25px;
    a {
      height: 100%;
    }

    .card-body {
      padding-bottom: 130px;
      h5 {
        font-size: 1.20625rem;
        font-weight: bold;
        margin-top: 22px;
        float: left;
        text-align: left;
        width: 100%;
        color: #0088cc;
      }
    }
  }
  .icon-area {
    background: #88d210;
    border-radius: 4px;
    float: left;
    text-align: center;
    padding: 22px;
    max-width: 125px;
    max-height: 125px;
    img {
      height: 80px;
      width: 80px;
    }
  }
}
