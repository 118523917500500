body.login {
  .srs-app-main {
    padding: 0;
  }

  .srs-app-main > .container {
    max-width: 100%;
  }
}

.srs-auth-login-background {
  background-image: url("../../../assets/images/recycling.jpg");
  background-size: cover;
}
